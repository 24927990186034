<template>
  <api-text-page
      :header="$t('security_events.title')"
      icon="elements"
      backTo="/getting-started"
      :backText="$t('common.getting_started')"
  >
    <div class="hds-table" v-html="markedContent"></div>

  </api-text-page>
</template>

<script>
import TextPage from "@/components/TextPage";
import {parse} from 'marked';

const securityEventsContent = `

# Security events:\n

|                   Product features                   |                                                                                        Event types                                                                                       |
|:-----------------------------------------------------|:-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
| Real-time protect                                    | Malware / spyware detected, certain action was taken                                                                                                                                     |
| DeepGuard                                            | Malware / suspicious application / riskware / rare application / ransomware blocked System / another process configuration attempt blocked Network access for an application was blocked |
| On-demand scanning (scheduled or manually triggered) | Malware / spyware detected, certain action was taken                                                                                                                                     |
| AMSI (antimalware scan interface)                    | Malware / spyware blocked                                                                                                                                                                |
| Firewall                                             | Firewall blocked a connection                                                                                                                                                            |
| Browsing protection                                  | Harmful / suspicious page blocked based on reputation score                                                                                                                              |
| Connection control                                   | Network connection blocked                                                                                                                                                               |
| Web content control                                  | Page belonging to certain category blocked                                                                                                                                               |
| Web traffic scanning                                 | Web page / binary blocked                                                                                                                                                                |
| Application control                                  | Application / module / installer start operation blocked Application start operation reported File access blocked File access reported App control rule is misconfigured                 |
| DataGuard                                            | Access to file blocked / Access to file reported                                                                                                                                           |
| Device control                                       | Device blocked Read / write access to mass storage device blocked Binary execution from mass storage device blocked                                                                      |
| Integrity checker (Linux)                            | File modification reported                                                                                                                                                               |
| Tamper protection                                    | File / registry / service modification attempt blocked Uninstallation operation was blocked                                                                                              |
| Server Share Protection                              | Prevented malicious modification on the server                                                                                                                   |
`;

export default {
  components: {
    'api-text-page': TextPage,
  },
  data() {
    return {
      markedContent: parse(securityEventsContent)
    }
  }
}
</script>
