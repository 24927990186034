import WelcomePage from "@/pages/Welcome";

import GettingStartedPage from "@/pages/GettingStarted";
import GSRdrApiPage from "@/pages/getting-started/rdr-api";
import GSProvisionApiPage from "@/pages/getting-started/provision-api";
import GSElementsApiPage from "@/pages/getting-started/elements-api";
import GSSecurityEvents from "@/pages/getting-started/security-events";
import GSWsPlatformApiPage from "@/pages/getting-started/ws-platform";
import CookbookElementsApiPage from "@/pages/getting-started/elements-api-cookbook";

import ApiPage from "@/pages/Api";
import ApiRdrPage from "@/pages/api/rdr";
import ApiProvisionPage from "@/pages/api/provision";
import ApiUsagePage from "@/pages/api/usage";
import ApiElementsPage from "@/pages/api/elements";
import ApiSecurityEventsPage from "@/pages/api/security-events";
import ApiWsPlatformPage from "@/pages/api/ws-platform";

import PageNotFound from "@/pages/PageNotFound";

import VueRouter from "vue-router";

const routes = [
    { path: '/', component: WelcomePage },

    { path: '/getting-started', component: GettingStartedPage },
    { path: '/getting-started/rdr', component: GSRdrApiPage, name: 'gs-rdr' },
    { path: '/getting-started/provisioning', component: GSProvisionApiPage, name: 'gs-provision' },
    { path: '/getting-started/elements', component: GSElementsApiPage, name: 'gs-elements' },
    { path: '/getting-started/security-events', component: GSSecurityEvents, name: 'gs-security-events' },
    { path: '/getting-started/ws-platform', component: GSWsPlatformApiPage, name: 'gs-ws-platform' },
    { path: '/getting-started/elements-cookbook', component: CookbookElementsApiPage, name: 'gs-cookbook'},


    { path: '/api-reference', component: ApiPage },
    { path: '/api-reference/rdr', component: ApiRdrPage, name: 'api-rdr' },
    { path: '/api-reference/provisioning', component: ApiProvisionPage, name: 'api-provision' },
    { path: '/api-reference/usage', component: ApiUsagePage, name: 'api-usage' },
    { path: '/api-reference/elements', component: ApiElementsPage, name: 'api-elements' },
    { path: '/api-reference/security-events', component: ApiSecurityEventsPage, name: 'api-security-events' },
    { path: '/api-reference/ws-platform', component: ApiWsPlatformPage, name: 'api-ws-platform' },

    { path: '*', component: PageNotFound }
]

export const router = new VueRouter({
    mode: 'history',
    routes
})
