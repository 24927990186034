<template>
  <div class="api-api-ref">
    <api-blue-header alignLeft>
      <template #title>
        {{ $t('common.api_ref') }}
      </template>
    </api-blue-header>

    <div class="hds-container api-page-content">
      <h2 class="hds-margin-large-bottom">{{ $t('api.description') }}</h2>

      <div class="hds-row api-gs-articles">
        <div class="hds-col-4">
          <api-card
              :to="{ name: 'api-elements' }"
              icon="elements"
              :desc="$t('api.elements_desc')"
          >
            {{ $t('common.elements_api') }}
          </api-card>
        </div>
        <div class="hds-col-4">
          <api-card
              href="https://api.radar-prd.fsapi.com/apidoc/index.html"
              icon="radar"
              :desc="$t('api.radar_desc')"
          >
            {{ $t('common.radar_api') }}
          </api-card>
        </div>
        <div class="hds-col-4">
          <api-card
              :to="{ name: 'api-provision' }"
              icon="epp"
              :desc="$t('api.provisioning_desc')"
          >
            {{ $t('common.prov_api') }}
          </api-card>
        </div>
        <div class="hds-col-4">
          <api-card
              :to="{ name: 'api-security-events' }"
              icon="elements"
              :desc="$t('api.security_events_desc')"
          >
            {{ $t('common.security_events') }}
          </api-card>
        </div>
        <div v-if="isLocalOrCiEnvironment" class="hds-col-4">
          <api-card
              :to="{ name: 'api-ws-platform' }"
              icon="elements"
              :desc="$t('api.security_events_desc')"
          >
            {{ $t('common.ws-platform') }}
          </api-card>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BlueHeader from "@/components/BlueHeader";
import Card from "@/components/Card";
import { mapGetters } from 'vuex'

export default {
  components: {
    'api-blue-header': BlueHeader,
    'api-card': Card,
  },
  computed: {
    ...mapGetters(['isLocalOrCiEnvironment', 'notProduction'])
  }
}
</script>

<style scoped>
</style>
