<template>
  <api-text-page
      :header="$t('ws-platform.title')"
      icon="elements"
      backTo="/getting-started"
      :backText="$t('common.getting_started')"
  >

    <h1>Getting started</h1>

    <p>Hello Developer!</p>

    <p>We offer you the superpower of being able to instantly check whether a file or a web address is malicious!</p>
    <p>Ready to unleash some cyber security? Only three really easy steps are required</p>
      <ol>
        <li>Get the API key</li>
        <li>Experiment with the API</li>
        <li>Build your solution</li>
      </ol>

    <h2>1. Get the API key</h2>
    <p>Buzz your contact in WithSecure and you will get the API key which unlocks the whole platform.</p>

    <h2>2. Experiment with the API</h2>
    <p>Ready to craft your first API call? Let’s try it out with simple check to see whether a web address is malicious!</p>
    <ol>
      <li>Authenticate.</li>
      <li>Design the API call. Input your web address in the proper format.</li>
      <li>Fire away.</li>
      <li>Check the response.</li>
    </ol>

    <p>Seems to be working? Easy isn’t it?</p>

    <p>Now pay attention to the field “verdict”. That’s your result. If it says Safe then all is clear - otherwise some actions would be needed. More about that in the next section.</p>

    <p>Similarly you can try out sending hashes of files or the actual files themselves.</p>

    <h2>3. Build the solution</h2>

    <p>Now as you’re fairly familiar with the solution, let’s go to the next level - that is designing and implementing your solution.</p>

    <ul>
      <li>Pick your poison - Hashes, Files or URLs
        <ol>
          <li>Hash - For a fast check whether a file is known malicious. It has the fastest response and you don’t need to send the file over the API in case that is a problem for your use case.</li>

          <li>Files - For checking whether the file is known malicious with analysis of the file included.</li>

          <li>URLs - For checking whether the URL is known malicious with analysis of the URL included.</li>
        </ol>
      </li>

      <li>Now that I got a response, What should I do with that?
        <ol>
          <li>Safe - WithSecure successfully analyzed the file/url and did not find anything alarming. You can continue processing the file.</li>
          <li>Unsafe - the file/url is malicious. Please discard the file and prompt a descriptive error to the user.</li>
          <li>Inconclusive - WithSecure could not determine whether the file is malicious or not. Depending on the use case and your risk appetite you can either handle it safe/unsafe or let the user take the responsibility. In case you want to dig deeper - see link here for more information.</li>
        </ol>
      </li>

      <li>Other important things to consider
        <ol>
          <li>Testing
            <ul>
              <li>Sample hash for testing:</li>
              <li>Sample files for testing: Download Anti Malware Testfile - EICAR</li>
              <li>Sample url for testing:</li>
            </ul>
          </li>
          <li>Handling exceptions
            <ul>
              <li>Exponential back off</li>
            </ul>
          </li>
          <li>
            Logging
          </li>
        </ol>
      </li>
    </ul>

    <h2>Advanced use</h2>
    <ul>
      <li>Optimizing calls
        <ul>
          <li>Using flags</li>
          <li>Caching</li>
        </ul>
      </li>

      <li>Asynchronous calls</li>

      <li>Verdicts and supplementary information
        <ul>
          <li>Categories</li>
          <li>Unknown supplements</li>
        </ul>
      </li>

      <li>Privacy
        <ul>
          <li>In case you want to make sure that a file is not send for analysis you can
            <ul>
              <li>Calculate the hash and send it instead.</li>
              <li>Omit the file based on characteristics your implementation detects.</li>
            </ul>
          </li>
        </ul>
      </li>

      <li>Advanced handling of Detected situations
        <ul>
          <li>In case you want to be able investigate a Detected case further, you can introduce a quarantine feature i.e. capture the file or url to a safe place and then alert the party doing the investigation. WithSecure consulting is also able to take on investigations when contracted.</li>
        </ul>
      </li>

      <li>A potential case where the verdict was later found out to be wrong.
        <ul>
          <li>While WithSecure has extensive intelligence and utilizes advances techniques to detect new malicious files and web addresses, we are unable to give 100% coverage.</li>
          <li>Verdict was undetected but was later found out to be malicious.
            <ul>
              <li>Sounds bad! We’d like to learn more about this. Please buzz your contact in WithSecure!</li>
            </ul>
          </li>
          <li>Verdict was detected but was later found out to be benign.
            <ul>
              <li>This may happen as well. For this very reason we recommend to carefully design the interaction so that your business process will not get stuck to the situation.</li>
              <li>We’d also like to learn more about this. Please buzz your contact in WithSecure!</li>
            </ul>
          </li>
        </ul>
      </li>

      <li>How about the privacy? I mean where do those sent files go and how do you handle the data?
        <ul>
          <li>The files are machine processed in WithSecure service in AWS Ireland in full compliancy to GDPR regulations. The identity of the customer is kept separate from the file. We do not store identifying information or the file but only the relevant metadata.</li>
        </ul>
      </li>
    </ul>
  </api-text-page>
</template>

<script>
import TextPage from "@/components/TextPage";

export default {
  components: {
    'api-text-page': TextPage,
  },
}
</script>
