<template>
  <div class="api-getting-started">
    <api-blue-header alignLeft>
      <template #title>
        {{ $t('common.getting_started') }}
      </template>
    </api-blue-header>

    <div class="hds-container api-page-content">
        <h2 class="hds-margin-large-bottom">{{ $t('getting_started.description') }}</h2>
        <div class="hds-row api-gs-articles">
          <div class="hds-col-4">
            <api-card
                icon="elements"
                :to="{ name: 'gs-elements' }"
                :desc="$t('getting_started.elements_desc')"
            >
              {{ $t('common.elements_api') }}
            </api-card>
          </div>
          <div class="hds-col-4">
            <api-card
              icon="radar"
              href="https://www.withsecure.com/userguides/product.html#business/radar/4.0/en/concept_35AD9E6374114D41B56275182796EE1A-4.0-en"
              :desc="$t('getting_started.radar_desc')"
              >
              {{ $t('common.radar_api') }}
            </api-card>
          </div>
          <div class="hds-col-4">
            <api-card
              icon="epp"
              :to="{ name: 'gs-provision' }"
              :desc="$t('getting_started.provision_desc')"
              >
              {{ $t('common.prov_api') }}
            </api-card>
          </div>
          <div class="hds-col-4">
            <api-card
                icon="elements"
                :to="{ name: 'gs-security-events' }"
                :desc="$t('getting_started.security_events_desc')"
            >
              {{ $t('common.security_events') }}
            </api-card>
          </div>
          <div v-if="isLocalOrCiEnvironment" class="hds-col-4">
            <api-card
                icon="elements"
                :to="{ name: 'gs-ws-platform' }"
                :desc="$t('getting_started.ws-platform_desc')"
            >
              {{ $t('common.ws-platform') }}
            </api-card>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
import BlueHeader from "@/components/BlueHeader";
import Card from "@/components/Card";
import { mapGetters } from 'vuex'

export default {
  components: {
    'api-blue-header': BlueHeader,
    'api-card': Card,
  },
  computed: {
    ...mapGetters(['isLocalOrCiEnvironment', 'notProduction'])
  }
}
</script>

<style scoped>
</style>
