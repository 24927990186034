import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueHds from '@fsecure/fsc-ui-framework-vuejs';
import HadesIcons from '@fsecure/hades-icons/dist/vue/hds-svg-icon.umd.min';
import i18n from './i18n';

import '@fsecure/hades/dist/styles.min.css';

import {router} from "./router";
import {store} from "./store";


Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueHds);
Vue.component('HdsSvgIcon', HadesIcons);

export const bus = new Vue();

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
