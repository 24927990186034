<template>
  <api-text-page :isApiPage="true">
    <rapi-doc
        :spec-url="specUrl"
        sort-tags="false"
        fill-request-fields-with-example="false"
        allow-try="false"
        allow-server-selection="false"
        render-style="read"
        sort-endpoints-by="summary"
        schema-style="table"
        schema-description-expanded="true"
        show-header="false"
        allow-authentication="false"
        allow-search="false"
        allow-advanced-search="false"
        allow-api-list-style-selection="false"
        show-components="false"
        primary-color="#006FDD"
        nav-bg-color="#f8f8f8"
        nav-text-color="#212121"
        nav-hover-text-color="#212121"
        text-color="#212121"
        bg-color="#fff"
    >
      <div v-if="downloadUrl" slot="overview">
        <a :href="publicPath + downloadUrl"
           download
           class="hds-button">
          {{ $t('common.download_spec') }}
        </a>
      </div>
    </rapi-doc>

  </api-text-page>
</template>

<script>
import 'rapidoc';
import TextPage from "@/components/TextPage";

export default {
  props: {
    specUrl: {
      type: String,
      default: ''
    },
    downloadUrl: {
      type: String,
      default: ''
    }
  },
  components: {
    'api-text-page': TextPage,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>
<style>
a.hds-button {
  color: #fff;
}
</style>
