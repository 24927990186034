<template>
  <div class="api-text-page" :class="isApiPage ? 'hds-container-fluid api-page-api' : 'hds-container'">

    <div v-if="header" class="api-text-page-header">
        <span v-if="icon" class="hds-products hds-products-fusion hds-icons-products">
            <hds-svg-icon v-if="icon === 'epp'" icon="ep-large" size="x-large" />
            <hds-svg-icon v-if="icon === 'radar'" icon="vm-large" size="x-large" />
            <hds-svg-icon v-if="icon === 'elements'" icon="logo" size="large" color="interactive" />
        </span>
        <h1 class="hds-h1">{{ header }}</h1>
    </div>    
    <api-back-button v-if="backTo" :to="backTo" :title="backText" />
    <h2 v-if="deprecated" class="hds-h2 deprecated">Warning: This API is deprecated. Deprecated endpoints that have reached end of life might be removed without further notice</h2>
    <slot />
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";

export default {
  props: {
    header: {
      type: String,
      default: ''
    },
    icon: {
      type: String
    },
    backTo: {
      type: [String, Object]
    },
    backText: {
      type: String
    },
    isApiPage: {
      type: Boolean,
      default: false
    },
    deprecated: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'api-back-button': BackButton,
  }
}
</script>

<style scoped>
  h2 {
    margin: 30px 0 20px;
  }
  h3 {
    margin: 30px 0 10px;
  }
  .api-text-page {
    margin-top: 20px;
  }
  .api-page-api {
      margin: 0;
      padding: 0;
  }
  .api-text-page p,
  .api-text-page li,
  .api-text-page ol,
  .api-text-page ul {
    line-height: 180%;
  }
  .hds-breadcrumbs {
    margin-bottom: 20px;
  }
  .api-text-page-header {
    display: flex;
    align-items: center;
    padding: 10px 0 30px;
    margin-bottom: 10px;
    border-bottom: 1px #f0f0f0 solid;
  }
  .api-text-page-header .hds-icons-products {
    margin-right: 30px;
  }
  rapi-doc {
    height: calc(100vh - 108px);
  }

  img {
    width: 100%;
    max-width: 750px;
    height: auto;
    margin-bottom: 12px;
    border: 1pt solid #f0f0f0;
    padding: 16px;
    border-radius: 4px;
  }

  .api-text-page >>> .hds-code-snippet {
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 8px;
  }
  th {
    background-color: #006fdd;
    color: white;
  }
  tr:nth-child(odd){
    background-color: #f8f8f8;
  }
  .deprecated {
      color: red;
  }
</style>
