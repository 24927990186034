<template>
    <api-page specUrl="/specs/ws-platform/openapi.json"
              downloadUrl="specs/ws-platform/openapi.json"
              sortEndpointsBy="none"
              allowAuth="true"
              serverSelect="false"/>
</template>

<script>
import ApiPage from "@/components/ApiPage";

export default {
    components: {
        'api-page': ApiPage,
    }
}
</script>
