<template>
  <div class="hds-index hds-background page-not-found">
    <div class="hds-container-alignment-top hds-text-center">
      <div class="hds-product-title hds-product-title-big">
        WithSecure™ <span>Elements Security Center</span>
      </div>
    </div>
    <div class="hds-container hds-container-alignment-centralized hds-text-center white">
      <div class="hds-error-page-code">404</div>
      <div class="hds-error-page-title">Page not found!</div>
      <div class="hds-error-page-description">The page you were looking for does not exist.</div>
      <div class="hds-error-page-actions">
        <button class="hds-button-secondary white" v-on:click="goBack()">Go back</button>
        <button class="hds-button hds-margin-x-small-left" v-on:click="goHome()">Go to homepage</button>
      </div>
    </div>
    <div class="hds-container-alignment-bottom hds-text-center">
      <hds-svg-icon icon="logo-horizontal" logo />
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      goHome() {
        this.$router.push('/')
      },
      goBack() {
        this.$router.go(-1)
      }
    }
  }
</script>

<style scoped>
.page-not-found {
  position: absolute;
  top: 0;
  z-index: 1;
}

.white {
  color: white
}
</style>
