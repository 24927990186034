<template>
  <component :is="tag"
      :to="to"
      :href="href"
      class="hds-background-main hds-cards hds-cards-products"
      :target="href ? '_blank' : '_self'"
  >

    <span v-if="icon" class="hds-products hds-products-fusion hds-icons-products">
        <hds-svg-icon v-if="icon === 'epp'" icon="ep-large" size="x-large"/>
        <hds-svg-icon v-if="icon === 'radar'" icon="vm-large" size="x-large"/>
        <hds-svg-icon v-if="icon === 'elements'" icon="logo" size="large" color="interactive"/>
    </span>

    <h1 class="hds-cards-products-title" :class="{'deprecated-title': deprecated}">
      <slot />
    </h1>
    <div class="hds-cards-meta">
        {{ desc }}
    </div>

    <div class="hds-cards-products-footer">
      <hds-svg-icon icon="arrow-right" color="interactive"/>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    to: {
      type: Object,
    },
    href: {
      type: String
    },
      desc: {
      type: String
    },
    isExternal: {
      type: Boolean,
      default: false
    },
    deprecated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tag() {
      if ( this.href ) {
        return "a" ;
      }
      return "router-link";
    }
  }
}
</script>

<style scoped>
  .hds-cards {
    position: relative;
    min-height: 260px;
  }
  .hds-cards-products-title {
    color: #212121;
  }
  .api-deprecated {
     color: red;
  }
  .deprecated-title {
      color: gray
  }
</style>
