<template>
  <models-page specUrl="/specs/security-events/openapi.yaml"
               downloadUrl="specs/security-events/api-spec.yaml"/>
</template>

<script>
import ModelsPage from "@/components/ModelsPage";


export default {
  components: {
    'models-page': ModelsPage,
  }
}
</script>
