<template>
<api-text-page :isApiPage="true" :deprecated="deprecated">
  <rapi-doc
    :spec-url="specUrl"
    :allow-try="allowTry"
    render-style="read"
    theme="light"
    load-fonts="false"
    default-schema-tab = "schema"
    missing-tags="true"
    :sort-endpoints-by="sortEndpointsBy"
    schema-style="table"
    schema-description-expanded="true"
    show-header='false'
    allow-search="false"
    allow-advanced-search="false"
    allow-api-list-style-selection ='false'
    show-components="false"
    primary-color="#006FDD"
    nav-bg-color="#f8f8f8"
    nav-text-color="#212121"
    nav-hover-text-color="#212121"
    text-color="#212121"
    bg-color="#fff"
    :allow-authentication="allowAuth"
    :allow-server-selection="serverSelect"
    >
    <div v-if="downloadUrl" slot="overview">
      <a :href="publicPath + downloadUrl"
         download
         class="hds-button">
        {{ $t('common.download_spec') }}
      </a>
    </div>
  </rapi-doc>
  
</api-text-page>
</template>

<script>
import 'rapidoc';
import TextPage from "@/components/TextPage";

export default {
  props: {
    specUrl: {
      type: String,
      default: ''
    },
    allowTry: {
      type: String,
      default: 'true'
    },
    downloadUrl: {
      type: String,
      default: ''
    },
    allowAuth: {
      type: String,
      default: 'true'
    },
    serverSelect: {
      type: String,
      default: 'true'
    },
    sortEndpointsBy: {
      type: String,
      default: 'summary'
    },
    deprecated: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'api-text-page': TextPage,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
}
</script>
<style>
a.hds-button {
    color: #fff;
}

/* This is a *hack* to apply css to curl examples */
rapi-doc::part(curl) {
  display: block;
  white-space: initial;
  padding: 12px;
}
</style>
