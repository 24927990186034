<template>
  <div class="api-blue-header">
    <div class="hds-container" :class="alignLeft ? 'hds-text-left' : 'hds-text-center'">
      <h1 class="hds-h1">
        <slot name="title"></slot>
      </h1>

      <slot name="default"></slot>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    alignLeft: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .api-blue-header {
    color: #fff;
    padding: 48px;
    background-position: 10% 70%;
    background-size: cover;
    background-image: url('~@/assets/bg.png');
  }
  h1 {
    font-size: 44px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  p {
    max-width: 900px;
  }
  .hds-text-center p {
    margin-left: auto;
    margin-right: auto;
  }
</style>
