<template>
  <footer>
    <div class="hds-container">
      <div class="hds-row api-footer-menu">
        <div class="hds-col-6">
          <router-link to="/">
            <hds-svg-icon class="logo" icon="logo-horizontal" />
          </router-link>
        </div>
        <div class="hds-col-6 hds-text-right">
          <ul>
            <li>
              <a href="https://www.withsecure.com/en/about-us/legal/terms#terms" target="_blank">
                {{ $t('common.terms') }}
              </a>
            </li> | <li>
              <a href="https://www.withsecure.com/en/about-us/legal/privacy#privacy" target="_blank">
                {{ $t('common.policy') }}
              </a>
            </li> | <li>
              <a href="https://www.withsecure.com" data-localize="copyrightLabelx">© WithSecure™ 2022</a>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
}
</script>

<style scoped>
  footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 75px;
    padding-top: 22px;
    background-color: #f6f6f6;
    font-size: 14px;
  }
  footer a {
    color: #9e9e9e;
  }
  footer a:hover {
    color: #000;
  }
  ul {
    list-style: none;
    color: #9e9e9e;
    padding-top: 6px;
  }
  li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
  }
  .logo {
    height: 100%;
    width: 100%;
  }
</style>
