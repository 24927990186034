<template>
    <api-page specUrl="/specs/psb/openapi.yaml" />
</template>

<script>
import ApiPage from "@/components/ApiPage";

export default {
    components: {
        'api-page': ApiPage,
    }
}
</script>
