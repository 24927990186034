<template>
  <api-text-page
      :header="$t('provision.title')"
      icon="epp"
      backTo="/getting-started"
      :backText="$t('common.getting_started')"
  >

    <h1>
      {{ $t('provision.intro_title') }}
    </h1>
    <p v-html="$t('provision.intro_str1')"></p>
    <h2>
      {{ $t('provision.intro_terminology_title') }}
    </h2>
    <ul>
      <li>
        {{ $t('provision.intro_terminology_str1') }}
      </li>
      <li>
        {{ $t('provision.intro_terminology_str2') }}
      </li>
      <li>
        {{ $t('provision.intro_terminology_str3') }}
      </li>
      <li>
        {{ $t('provision.intro_terminology_str4') }}
      </li>
      <li>
        {{ $t('provision.intro_terminology_str5') }}
      </li>
      <li>
        {{ $t('provision.intro_terminology_str6') }}
      </li>
    </ul>
    <br>

    <h1>
      {{ $t('provision.useprovapi_title') }}
    </h1>
    <p>
      {{ $t('provision.useprovapi_str1') }}
    </p>

    <table class="hds-table">
      <tr>
        <th>
          <span>{{ $t('provision.useprovapi_t1th1') }}</span>
          <br>
          <span>{{ $t('provision.useprovapi_t1th1_subtext') }}</span>
        </th>
        <th>{{ $t('provision.useprovapi_t1th2') }}</th>
        <th>{{ $t('provision.useprovapi_t1th3') }}</th>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r1td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r1td2') }}</td>
        <td>
          <span>{{ $t('provision.useprovapi_t1r1td3') }}</span>
          <br>
          <span>{{ $t('provision.useprovapi_t1r1td3_subtext') }}</span>
        </td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r2td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r2td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r2td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r3td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r3td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r3td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r4td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r4td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r4td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r5td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r5td2') }}</td>
        <td>
          <span>{{ $t('provision.useprovapi_t1r5td3') }}</span>
          <br>
          <span>{{ $t('provision.useprovapi_t1r5td3_subtext') }}</span>
        </td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r6td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r6td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r6td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r7td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r7td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r7td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r8td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r8td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r8td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r9td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r9td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r9td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r10td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r10td2') }}</td>
        <td>
          <span>{{ $t('provision.useprovapi_t1r10td3') }}</span>
          <ul>
            <li>
              {{ $t('provision.useprovapi_t1r10td3_subtext1') }}
            </li>
            <li>
              {{ $t('provision.useprovapi_t1r10td3_subtext2') }}
            </li>
          </ul>
          <span>{{ $t('provision.useprovapi_t1r10td3_subtext3') }}</span>
        </td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r12td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r12td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r12td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r13td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r13td2') }}</td>
        <td>
          <span>{{ $t('provision.useprovapi_t1r13td3') }}</span>
          <br>
          <span>{{ $t('provision.useprovapi_t1r13td3_subtext') }}</span>
        </td>
      </tr>
     <tr>
        <td>{{ $t('provision.useprovapi_t1r20td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r20td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r20td3') }}</td>
      </tr>
       <tr>
        <td>{{ $t('provision.useprovapi_t1r18td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r18td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r18td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r19td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r19td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r19td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r15td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r15td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r15td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r16td1') }}</td>
        <td>
          <span>{{ $t('provision.useprovapi_t1r16td2_sub1') }}</span>
          <br>
          <span>{{ $t('provision.useprovapi_t1r16td2_sub2') }}</span>
        </td>
        <td>{{ $t('provision.useprovapi_t1r16td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r17td1') }}</td>
        <td>
          <span>{{ $t('provision.useprovapi_t1r17td2_sub1') }}</span>
          <br>
          <span>{{ $t('provision.useprovapi_t1r17td2_sub2') }}</span>
        </td>
        <td>{{ $t('provision.useprovapi_t1r17td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r14td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r14td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r14td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r21td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r21td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r21td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r22td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r22td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r22td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t1r11td1') }}</td>
        <td>{{ $t('provision.useprovapi_t1r11td2') }}</td>
        <td>{{ $t('provision.useprovapi_t1r11td3') }}</td>
      </tr>
    </table>
    <br>

    <p>
      {{ $t('provision.useprovapi_str2') }}
      {{ $t('provision.useprovapi_str3') }}
    </p>
    <table class="hds-table">
      <tr>
        <th>
          <span>{{ $t('provision.useprovapi_t2th1') }}</span>
          <br>
          <span>{{ $t('provision.useprovapi_t2th1_subtext') }}</span>
        </th>
        <th>{{ $t('provision.useprovapi_t2th2') }}</th>
        <th>{{ $t('provision.useprovapi_t2th3') }}</th>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t2r1td1') }}</td>
        <td>{{ $t('provision.useprovapi_t2r1td2') }}</td>
        <td>{{ $t('provision.useprovapi_t2r1td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t2r2td1') }}</td>
        <td>{{ $t('provision.useprovapi_t2r2td2') }}</td>
        <td>{{ $t('provision.useprovapi_t2r2td3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.useprovapi_t2r3td1') }}</td>
        <td>{{ $t('provision.useprovapi_t2r3td2') }}</td>
        <td>{{ $t('provision.useprovapi_t2r3td3') }}</td>
      </tr>
    </table>
    <br>
    <br>

    <h1>
      {{ $t('provision.auth_title') }}
    </h1>
    <p>
      {{ $t('provision.auth_str1') }}
    </p>
    <p>
      {{ $t('provision.auth_str2') }}
    </p>
    <p>
        {{ $t('provision.auth_str5') }}
        {{ $t('provision.auth_str6') }}
    </p>
    <br>

    <h1>
      {{ $t('provision.product_title') }}
    </h1>
    <p>
      {{ $t('provision.product_str1') }}
    </p>
    <p>
      {{ $t('provision.product_str2') }}
    </p>
    <p>
      {{ $t('provision.product_str3') }}
    </p>
    <table class="hds-table">
      <tr>
        <th>{{ $t('provision.product_t1th1') }}</th>
        <th>{{ $t('provision.product_t1th2') }}</th>
        <th>{{ $t('provision.product_t1th3') }}</th>
        <th>{{ $t('provision.product_t1th4') }}</th>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r1td1') }}</td>
        <td>{{ $t('provision.product_t1r1td2') }}</td>
        <td>{{ $t('provision.product_t1r1td3') }}</td>
        <td>{{ $t('provision.product_t1r1td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r4td1') }}</td>
        <td>{{ $t('provision.product_t1r4td2') }}</td>
        <td>{{ $t('provision.product_t1r4td3') }}</td>
        <td>{{ $t('provision.product_t1r4td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r5td1') }}</td>
        <td>{{ $t('provision.product_t1r5td2') }}</td>
        <td>{{ $t('provision.product_t1r5td3') }}</td>
        <td>{{ $t('provision.product_t1r5td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r6td1') }}</td>
        <td>{{ $t('provision.product_t1r6td2') }}</td>
        <td>{{ $t('provision.product_t1r6td3') }}</td>
        <td>{{ $t('provision.product_t1r6td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r7td1') }}</td>
        <td>{{ $t('provision.product_t1r7td2') }}</td>
        <td>{{ $t('provision.product_t1r7td3') }}</td>
        <td>{{ $t('provision.product_t1r7td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r8td1') }}</td>
        <td>{{ $t('provision.product_t1r8td2') }}</td>
        <td>{{ $t('provision.product_t1r8td3') }}</td>
        <td>{{ $t('provision.product_t1r8td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r9td1') }}</td>
        <td>{{ $t('provision.product_t1r9td2') }}</td>
        <td>{{ $t('provision.product_t1r9td3') }}</td>
        <td>{{ $t('provision.product_t1r9td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r10td1') }}</td>
        <td>{{ $t('provision.product_t1r10td2') }}</td>
        <td>{{ $t('provision.product_t1r10td3') }}</td>
        <td>{{ $t('provision.product_t1r10td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r12td1') }}</td>
        <td>{{ $t('provision.product_t1r12td2') }}</td>
        <td>{{ $t('provision.product_t1r12td3') }}</td>
        <td>{{ $t('provision.product_t1r12td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r13td1') }}</td>
        <td>{{ $t('provision.product_t1r13td2') }}</td>
        <td>{{ $t('provision.product_t1r13td3') }}</td>
        <td>{{ $t('provision.product_t1r13td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r14td1') }}</td>
        <td>{{ $t('provision.product_t1r14td2') }}</td>
        <td>{{ $t('provision.product_t1r14td3') }}</td>
        <td>{{ $t('provision.product_t1r14td4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r15td1') }}</td>
        <td>{{ $t('provision.product_t1r15td2') }}</td>
        <td>{{ $t('provision.product_t1r15td3') }}</td>
        <td>{{ $t('provision.product_t1r15td4') }}</td>
      </tr>
      <tr>
        <td colspan="4">{{ $t('provision.product_str4') }}</td>
      </tr>
      <tr>
        <td>{{ $t('provision.product_t1r11td1') }}</td>
        <td>{{ $t('provision.product_t1r11td2') }}</td>
        <td>{{ $t('provision.product_t1r11td3') }}</td>
        <td>{{ $t('provision.product_t1r11td4') }}</td>
      </tr>
    </table>
    <br>
    <br>

    <h1>
      {{ $t('provision.rollback_title') }}
    </h1>
    <p>
      {{ $t('provision.rollback_str1') }}
    </p>
    <br>
    <br>

    <h1>
      {{ $t('provision.env_title') }}
    </h1>
    <p v-html="$t('provision.env_str1')"></p>
    <br>

  </api-text-page>
</template>

<script>
import TextPage from "@/components/TextPage";

export default {
  components: {
    'api-text-page': TextPage,
  },
}
</script>

