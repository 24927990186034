<template>
    <api-page specUrl="/specs/elements/rendered/openapi.yaml"
              downloadUrl="specs/elements/rendered/api-spec.yaml"
              allowTry="false"
              allowAuth="false"
              serverSelect="false"/>
</template>

<script>
import ApiPage from "@/components/ApiPage";

export default {
    components: {
        'api-page': ApiPage,
    }
}
</script>
