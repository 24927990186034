<template>
  <div class="api-header-wrapper">
    <header class="hds-layout-header hds-header-new">
      <div class="hds-header-wrapper-menus">
        <div class="hds-container">
          <div class="hds-row">
            <div class="hds-col-6">
              <a href="/">
                <hds-svg-icon class="hds-padding-small logo" icon="logo-horizontal" color="bright"/>
              </a>
            </div>
            <div class="hds-col-6 hds-text-right">
              <api-menu isWhite :items="headerItems" />
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="hds-layout-header hds-header hds-header-condensed">
      <div class="hds-container">
        <div class="hds-row">
          <div class="hds-col-6">
            <api-menu :items="menuItems" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./Menu";

export default {
  components: {
    'api-menu': Menu,
  },
  data() {
    return {
      headerItems: [
        { name: this.$t('common.community'), to: "https://community.withsecure.com/", external: true },
        { name: this.$t('common.user_guides'), to: "https://help.withsecure.com/", external: true  },
        { name: this.$t('common.support'), to: "https://www.withsecure.com/en/support", external: true  },
        { name: this.$t('common.change_log'), to: "https://community.withsecure.com/discussion/124816/elements-api-change-log#latest", external: true  }
      ],
      menuItems: [
        { name: this.$t('common.home'), to: "/", exact: true },
        { name: this.$t('common.getting_started'), to: "/getting-started" },
        { name: this.$t('common.api_ref'), to: "/api-reference" },
      ]
    }
  }
}
</script>

<style scoped>
    .logo {
      height: 100%;
      width: 100%;
    }
    .api-header-wrapper {
        position: relative;
        z-index: 10;
    }
    .hds-header-new {
        height: 54px !important;
    }
    .hds-header {
        padding-top: 0 !important;
        border: none;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        background: #fff;
    }
    .hds-header-product-logo-symbol {
        padding-left: 14px;
    }
</style>
