<template>
  <api-text-page
      :header="$t('rdr.title')"
      icon="arrow"
      backTo="/getting-started"
      :backText="$t('common.getting_started')"
  >
  </api-text-page>
</template>

<script>
import TextPage from "@/components/TextPage";

export default {
  components: {
    'api-text-page': TextPage,
  },
}
</script>

<style scoped>

</style>
