import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        isLocalOrCiEnvironment: window.location.hostname === "localhost" || window.location.hostname === "connect-ci.fsxt.net",
        notProduction: window.location.hostname !== 'connect.withsecure.com'
    },
    getters: {
        isLocalOrCiEnvironment: state => {
            return state.isLocalOrCiEnvironment;
        },
        notProduction: state => {
            console.log('notProduction', window.location.hostname !== 'connect.withsecure.com');
            return state.notProduction;
        }
    }
})
