<template>
    <api-page specUrl="/specs/usage/openapi.yaml" allowTry='false' />
</template>

<script>
import ApiPage from "@/components/ApiPage";

export default {
    components: {
        'api-page': ApiPage,
    }
}
</script>