<template>
  <api-text-page
      :header="$t('elements.title')"
      icon="elements"
      backTo="/getting-started"
      :backText="$t('common.getting_started')"
  >
    <p>WithSecure Elements API provides an interface to access the WithSecure products through the REST API endpoints.
      The supported endpoints can be used by partners, resellers, or organizations. You can find a list of currently supported
      endpoints on the <a href="/api-reference/elements#overview">API specification page</a>.
      The existing API is gradually extended with new products and resources. </p>  
      <p>The introduction covers the following topics:</p>
    <ol>
      <li>Getting client credentials</li>
      <li>Client authentication</li>
      <li>Making requests</li>
      <li>Monitoring device status</li>
      <li>Retrieving security events</li>
      <li>Troubleshooting</li>
      <li>API deprecation policy</li>
    </ol>
    <h2 id="getting-client-credentials">Getting client credentials</h2>
    <p>To use Elements API, you need client credentials. 
      As an EPP administrator with <code>security management rights</code> and permission to edit computer 
      and server settings, you can create client credentials in <code>Elements Security Center</code>.
    </p>
    <p>
      You cannot, however, edit existing clients. If you need a client with different permissions, you must
      remove unused clients and generate a new pair of credentials with a relevant access scope.
    </p>
    <p><strong>To create Elements API credentials</strong></p>
    <ol>
      <li>Log in as an EPP administrator in <a href="https://elements.withsecure.com">Elements Security Center</a>.</li>
      <li>Under <code>Management</code>, select <code>Organization Settings</code>.</li>
      <li>In the <code>Organization Settings</code>view, from the menu located at the top select <code>API clients</code>.</li>
      <li>From the scope selector, change your scope to the <code>organization</code> for which you want to create a new pair of     
      credentials. Note: If you are a partner and you want to create credentials for an organization, you have to change scope to
        the organization for which you are issuing the credentials.
        <img :src="keysViewImg" alt="">
      </li>
      <li>Select <code>Add new</code>.</li>
      <li>
        In the <code>Add new</code> API client window, do the following:
        <ol>
            <li> Enter a description for the new client credentials.</li>
            <li> Select "Read-only" to allow the client only to read the data. Note: If you want the client to send requests to edit data on the server, for 
         example to trigger new remote operations, clear the checkbox next to "Read-only".  Then, the 
            client can request authentication tokens within the scope <code>connect.api.write</code>.</li>
            <li>Select Add.</li>
         </ol>

<img :src="creationWizardImg" alt="">
      </li>
      <li>Follow the instructions on the screen. Important: Remember to save the secret value in a safe place, because you 
      are not be able to read the value again.</li>
      <li>Select the "I have copied and stored the secret" option, and then select <code>Done</code>.
<img :src="copyKeysImg" alt="">
      </li>
      <li>The new item is added to the list.
<img :src="keysListImg" alt="">
      </li>
    </ol>
    <p><strong>To delete Elements API credentials</strong></p>
    <p>As an EPP administrator, you can remove existing client credentials at any time. 
      Removing credentials breaks any integration that is using removed secrets.
      Removed credentials cannot be restored.</p>
    <ol>
      <li>Log in as an EPP administrator in <a href="https://elements.withsecure.com">Elements Security Center</a>.</li>
      <li>Under <code>Management</code>, select <code>API client</code>.</li>
      <li>From the scope selector, change your scope to the <code>organization</code> whose credentials you want to remove.</li>
      <li>In the list, find the credentials that you want to remove and select the trash bin icon in the last column.</li>
      <li>Select <code>Delete</code>to confirm the operation.
<img :src="deleteKeyImg" alt="">
      </li>
    </ol>
    <h2 id="client-authentication">Client authentication</h2>
    <p>Before sending any requests to Elements API, a client must authenticate itself and fetch 
      a security token. 
      Elements API provides an authentication endpoint <a href="/api-reference/elements#post-/as/token.oauth2">/as/token.oauth2</a> 
      which the client uses to obtain a token.
      Credentials are sent in the "Authorization" header encoded with Base64 in POST request.
      <span>
        The body should be form data with properties: <code>grant_type</code> equal <code>client_credentials</code>
        and <code>scope</code> that contain the requested access scope:
        <ul>
          <li>If a client is supposed to change the state of EDR incidents or devices, trigger remote operations, or modify data in any other
          way, add the following scope: <code>connect.api.write</code>.</li>
          <li>If a client sends requests to only read data from the server, for example, to read EDR detections, add the following scope:
          <code>connect.api.read</code>.</li>
        </ul>
        You must set a <code>User-agent</code> header. Otherwise, a request is rejected.
      </span>
    </p>
    <p><strong>Raw request</strong></p>
    <hds-code-snippet code="POST /as/token.oauth2 HTTP/1.1
Host: api.connect.withsecure.com
user-agent: command-line
content-type: application/x-www-form-urlencoded
Authorization: Basic &lt;credentials_base_64&gt;
grant_type=client_credentials
scope=connect.api.read" lang="shell" header="HTTP request"/>
    <p><strong>Javascript client</strong></p>
    <hds-code-snippet code="const client = &#39;...&#39; // client id;
const secret = &#39;...&#39; // secret value
const basic = btoa(`${client}:${secret}`);
const opts = {
  headers: {
    &#39;Authorization&#39;: `Basic ${basic}`,
    &#39;user-agent&#39;: &#39;NodeJs&#39;,
    &#39;Content-type&#39;: &#39;application/x-www-form-urlencoded&#39;
  },
  method: &#39;POST&#39;
};
const url = &#39;https://api.connect.withsecure.com/as/token.oauth2&#39;
const req = https.request(url, opts, (r) =&gt; {
  let raw = &#39;&#39;;
  r.on(&#39;data&#39;, (d) =&gt;  { raw += d; });
  r.on(&#39;end&#39;, () =&gt; { console.log(raw); });
});
req.write(&#39;grant_type=client_credentials&#39;);
req.write(&#39;scope=connect.api.read&#39;);
req.end();
" lang="javascript" header="Request from JavaScript client"/>
    <p>If the request and credentials are valid, the authentication endpoint answers with an access token</p>
    <hds-code-snippet code="{&quot;access_token&quot;:&quot;FdZTq11111116v67lSOx7vyFM2ssnIha&quot;,&quot;token_type&quot;:&quot;Bearer&quot;,&quot;expires_in&quot;:1797}"
                      lang="json" header="Response from request" />
    <h2 id="making-request">Making request</h2>
    <p>Every request that is sent to Elements API must include an access token that is received 
      from an authentication endpoint. The token must be sent in the <code>Authorization</code> header with a <code>Bearer</code> 
      schema. 
      If a request is successful, Elements API answers with HTTP status <code>200 (OK)</code> and result data in response body. 
      Otherwise,  the client receives response with one of the following error status codes: <code>4xx</code> or <code>5xx</code>.
      Each response includes an <code>x-transaction</code> header that the customer support can use for investigation.</p>
    <p><strong>Raw request</strong></p>
    <hds-code-snippet code="GET /whoami/v1/whoami HTTP/1.1
Host: api.connect.withsecure.com
user-agent: command-line
content-type: x-www-form-urlencoded
Authorization: Bearer &lt;access_token&gt;
" lang="shell" header="HTTP request"/>
    <p><strong>Javascript client</strong></p>
    <hds-code-snippet code="const token = &#39;...&#39;; // access token
const opts = {
  headers: {
    &#39;Authorization&#39;: `Bearer ${token}`,
    &#39;user-agent&#39;: &#39;NodeJs&#39;
  },
  method: &#39;GET&#39;
};
const url = &#39;https://api.connect.withsecure.com/whoami/v1/whoami&#39;
const req = https.request(url, opts, (r) =&gt; {
  let raw = &#39;&#39;;
  r.on(&#39;data&#39;, (d) =&gt;  { raw += d; });
  r.on(&#39;end&#39;, () =&gt; { console.log(raw); });
});
req.end();
" lang="javascript" header="Request from JavaScript client"/>
    <hds-code-snippet code="{&quot;clientId&quot;:&quot;fusion_aae404cb18404fabcede&quot;, organizationId&quot;:&quot;99999f73-1ee1-414f-b7bb-10a53242759e&quot;}"
                      lang="json" header="Response from request"/>

    <h3>Timestamps format</h3>

    <p>Elements API endpoints accept timestamps in a format that matches the 
    <a href="https://datatracker.ietf.org/doc/html/rfc3339#section-5.6">RFC 3339 specification</a>:
      <ul>
        <li>timestamp in the UTC time zone: <b>2022-11-05T15:08:59Z</b></li>
        <li>timestamp in a time zone with an offset from UTC of -08:00: <b>2022-11-05T07:08:59-08:00</b></li>
        <li>timestamp in a time zone with an offset from UTC of +02:00: <b>2022-11-05T07:17:59+02:00</b></li>
      </ul>
    </p>
    <p>If an endpoint returns a collection, the items are split, showing up to 200 elements on each page. A client can
      iterate through a collection using a value from the <code>nextAnchor</code> property that is included in 
      the response on each page.</p>
    <hds-code-snippet code="{
  items: [
    {
      &quot;id&quot;: &quot;66787bfd-3fec-4355-955b-790b53ee0199&quot;,
      &quot;name&quot;: &quot;Example company 1&quot;
    },
    {
      &quot;id&quot;: &quot;6827ac27-67b3-45c5-bdd3-69660e8272a8&quot;,
      &quot;name&quot;: &quot;Example company 2&quot;
    },
    {
      &quot;id&quot;: &quot;30ec2ab5-7d4b-458c-bd66-ed099fe42398&quot;,
      &quot;name&quot;: &quot;Example company 3&quot;
    }
  }],
  &quot;nextAnchor&quot;: &quot;anchor-to-next-page&quot;
}"
                      lang="json" header="Response from request"
    />
    <hds-code-snippet code="GET /organizations/v1/organizations?anchor=anchor-to-next-page&amp;limit=10 HTTP/1.1
Host: api.connect.withsecure.com
user-agent: command-line
content-type: x-www-form-urlencoded
Authorization: Bearer &lt;access_token&gt;
" lang="shell" header="Next page: HTTP request"/>
    <hds-code-snippet code="const token = &#39;...&#39;; // access token
const opts = {
  headers: {
    &#39;Authorization&#39;: `Bearer ${token}`,
    &#39;user-agent&#39;: &#39;NodeJs&#39;
  },
  method: &#39;GET&#39;
};
const query = &#39;anchor=anchor-to-next-page&amp;limit=10&#39; // limit defines page max size
const url = `https://api.connect.withsecure.com/organizations/v1/organizations?${query}`
const req = https.request(url, opts, (r) =&gt; {
  let raw = &#39;&#39;;
  r.on(&#39;data&#39;, (d) =&gt;  { raw += d; });
  r.on(&#39;end&#39;, () =&gt; { console.log(raw); });
});
req.end();" lang="javascript" header="Next page: JavaScript client"/>
    <h2 id="protection-status">Monitoring status of devices</h2>
    <p>
      A client can monitor the status of any device that belongs to its organization's scope. If a client is using
      credentials that are created on a partner level, it has access to any device that belongs to the child organization.
    </p>
    <p><strong>Listing devices with critical protection status</strong></p>
    <p>
      In the example, a client is authenticated with client credentials that are created on a partner level.
      A request URL contains the <code>organizationId</code> parameter with a company identifier. Because the second parameter, <code>protectionStatusOverview</code> is equal to <code>critical</code>, the response includes only devices with Critical 
      protection status.
    </p>
    <hds-code-snippet code="GET /devices/v1/devices?organizationId=e4bd56b0-90b4-4878-962f-903ea862a977&protectionStatusOverview=critical HTTP/1.1
Host: api.connect.withsecure.com
user-agent: command-line
content-type: x-www-form-urlencoded
Authorization: Bearer &lt;access_token&gt;
" lang="shell" header="HTTP request"/>
    <hds-code-snippet code="const token = &#39;...&#39;; // access token
const opts = {
  headers: {
    &#39;Authorization&#39;: `Bearer ${token}`,
    &#39;user-agent&#39;: &#39;NodeJs&#39;
  },
  method: &#39;GET&#39;
};
const query = &#39;organizationId=e4bd56b0-90b4-4878-962f-903ea862a977&protectionStatusOverview=critical&#39;
const url = `https://api.connect.withsecure.com/devices/v1/devices?${query}`
const req = https.request(url, opts, (r) =&gt; {
  let raw = &#39;&#39;;
  r.on(&#39;data&#39;, (d) =&gt;  { raw += d; });
  r.on(&#39;end&#39;, () =&gt; { console.log(raw); });
});
req.end();" lang="javascript" header="Request from JavaScript client"/>
    <p><strong>Listing devices that are missing critical updates</strong></p>
    <p>
      In the example, a client is authenticated with client credentials that are created on an organization level.
      A <code>patchOverallState</code> parameter is used to query devices that are missing critical updates.
    </p>
    <hds-code-snippet code="GET /devices/v1/devices?patchOverallState=missingCriticalUpdates HTTP/1.1
Host: api.connect.withsecure.com
user-agent: command-line
content-type: x-www-form-urlencoded
Authorization: Bearer &lt;access_token&gt;
" lang="shell" header="HTTP request"/>
    <hds-code-snippet code="const token = &#39;...&#39;; // access token
const opts = {
  headers: {
    &#39;Authorization&#39;: `Bearer ${token}`,
    &#39;user-agent&#39;: &#39;NodeJs&#39;
  },
  method: &#39;GET&#39;
};
const query = &#39;patchOverallState=missingCriticalUpdates&#39;
const url = `https://api.connect.withsecure.com/devices/v1/devices?${query}`
const req = https.request(url, opts, (r) =&gt; {
  let raw = &#39;&#39;;
  r.on(&#39;data&#39;, (d) =&gt;  { raw += d; });
  r.on(&#39;end&#39;, () =&gt; { console.log(raw); });
});
req.end();" lang="javascript" header="Request from JavaScript client"/>
    <h2 id="security-events">Retrieving security events</h2>
    <p>
      You can retrieve security events for any organization that is in the scope of Elements API client credentials.
      If credentials are created on a partner level, a client can read security events of organizations that belong
      to the partner account.
    </p>
    <p>
      When a client tries to retrieve security events, it must specify one of the following time ranges:
      <ul>
        <li>start time with the following request parameter to read all events that persisted <b>after</b> the specified date: 
        <code>persistenceTimestampStart</code></li>
        <li>end time with the following request parameter to read all events that persisted <b>before</b> the specified date:
        <code>persistenceTimestampEnd</code></li>
        <li>start and end time with the following request parameters to read all events that persisted <b>between</b> the specified 
        dates: <code>persistenceTimestampStart</code> and <code>persistenceTimestampEnd</code></li>
      </ul>
      If a client does not specify any time range parameters, the request fails.
    </p>
    <p><strong>Reading all events in the partner scope</strong></p>
    <p>In the example, a client is authenticated with client credentials that are created on a partner level.
      An <code>organizationId</code> parameter is not specified in the request URL, so Elements API returns events from all
      organizations that belong to the partner's organization and that were created before 2023-05-01.
    </p>
    <hds-code-snippet code="POST /security-events/v1/security-events HTTP/1.1
Host: api.connect.withsecure.com
user-agent: command-line
content-type: application/x-www-form-urlencoded
Authorization: Bearer &lt;access_token&gt;
persistenceTimestampEnd=2023-05-01T00:00:00Z&engineGroup=epp
" lang="shell" header="HTTP request"/>
    <hds-code-snippet code="const token = &#39;...&#39;; // access token
const opts = {
  headers: {
    &#39;Authorization&#39;: `Bearer ${token}`,
    &#39;Content-type&#39;: &#39;application/x-www-form-urlencoded&#39;,
    &#39;user-agent&#39;: &#39;NodeJs&#39;
    
  },
  method: &#39;POST&#39;
};
const form = &#39;persistenceTimestampEnd=2023-05-01T00:00:00Z&engineGroup=epp&#39;
const url = `https://api.connect.withsecure.com/security-events/v1/security-events`
const req = https.request(url, opts, (r) =&gt; {
  let raw = &#39;&#39;;
  r.on(&#39;data&#39;, (d) =&gt;  { raw += d; });
  r.on(&#39;end&#39;, () =&gt; { console.log(raw); });
});
req.write(form);
req.end();" lang="javascript" header="Request from JavaScript client"/>
    <p><strong>Reading all events from an organization</strong></p>
    <p>In the example, a client is authenticated with client credentials that are created on a partner level.
      An <code>organizationId</code> parameter is present in the request URL, so Elements API returns events that were created
      after 2023-05-01 for the organization with the given identifier.
    </p>
    <hds-code-snippet code="POST /security-events/v1/security-events HTTP/1.1
Host: api.connect.withsecure.com
user-agent: command-line
content-type: application/x-www-form-urlencoded
Authorization: Bearer &lt;access_token&gt;
organizationId=e4bd56b0-90b4-4878-962f-903ea862a977&persistenceTimestampStart=2023-05-01T00:00:00Z&engineGroup=epp
" lang="shell" header="HTTP request"/>
    <hds-code-snippet code="const token = &#39;...&#39;; // access token
const opts = {
  headers: {
    &#39;Authorization&#39;: `Bearer ${token}`,
    &#39;Content-type&#39;: &#39;application/x-www-form-urlencoded&#39;,
    &#39;user-agent&#39;: &#39;NodeJs&#39;
  },
  method: &#39;POST&#39;
};
const form = &#39;organizationId=e4bd56b0-90b4-4878-962f-903ea862a977&persistenceTimestampStart=2023-05-01T00:00:00Z&engineGroup=epp&#39;
const url = `https://api.connect.withsecure.com/security-events/v1/security-events`
const req = https.request(url, opts, (r) =&gt; {
  let raw = &#39;&#39;;
  r.on(&#39;data&#39;, (d) =&gt;  { raw += d; });
  r.on(&#39;end&#39;, () =&gt; { console.log(raw); });
});
req.write(form);
req.end();" lang="javascript" header="Request from JavaScript client"/>
    <h2 id="troubleshooting">Troubleshooting</h2>
    <p>
      Sometimes request cannot be completed due to errors on a client or server side:
      <ul>
        <li>When a client sends wrong parameters in its request</li>
        <li>When requested resources do not exist</li>
        <li>When a client does not have access to the requested resources</li>
        <li>When a request to an internal backend fails</li>
      </ul>
    </p>
    <p>
      In such cases, a response includes error details and a numeric error code. Error codes are described
      in the <a href="/api-reference/elements#overview">API specification page</a>. If a request cannot be completed
      due to a client error, checking the error details might help in fixing the issue.

      <img :src="exampleError" alt="">
    </p>
    <h2 id="deprecation-policy">API deprecation policy</h2>
    <p>As our system evolves and we introduce new functionalities, we may face 
      the need to terminate some existing functionalities in the APIs. In such cases, we will 
      mark the functionality as deprecated in the documentation. We will also announce the change 
      in the <a href="https://community.withsecure.com/discussion/124816/elements-api-change-log#latest">Elements API change log</a>.</p>

  </api-text-page>
</template>

<script>
import TextPage from "@/components/TextPage";
import keysViewImg from '@/assets/getting-started/elements-api-creds-1.png';
import descriptionImg from '@/assets/getting-started/elements-api-creds-2.png';
import creationWizardImg from '@/assets/getting-started/elements-api-creds-2.1.png';
import copyKeysImg from '@/assets/getting-started/elements-api-creds-3.png';
import keysListImg from '@/assets/getting-started/elements-api-creds-4.png';
import deleteKeyImg from '@/assets/getting-started/elements-api-creds-5.png';
import exampleError from '@/assets/getting-started/elements-api-error.png';

export default {
  components: {
    'api-text-page': TextPage,
  },
  data() {
    return {
        keysViewImg,
        deleteKeyImg,
        descriptionImg,
        creationWizardImg,
        keysListImg,
        copyKeysImg,
        exampleError
    }
  }
}
</script>
