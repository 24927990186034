<template>
  <div class="hds-breadcrumbs">
    <router-link :to="to" class="hds-link hds-link-breadcrumbs">
      <hds-svg-icon icon="arrow-left" color="interactive"/>
      {{ $t('common.back') }} {{ title }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
  .hds-breadcrumbs {
    padding: 0 0 10px;
    border-bottom: 1px #f0f0f0 solid;
  }
</style>
