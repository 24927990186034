import { render, staticRenderFns } from "./rdr-api.vue?vue&type=template&id=661528d6&scoped=true"
import script from "./rdr-api.vue?vue&type=script&lang=js"
export * from "./rdr-api.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661528d6",
  null
  
)

export default component.exports