<template>
  <div class="hds-tabs" :class="{ 'white-menu': isWhite }">
    <template v-for="(link, key) in items">
      <router-link
          v-if="!link.external"
          :key="key"
          :to="link.to"
          :exact="link.exact"
          active-class="hds-tabs-item-active"
          class="hds-tabs-item">
        {{ link.name }}
      </router-link>

      <a v-else :href="link.to" class="hds-tabs-item" target="_blank" :key="key">
        {{ link.name }}
      </a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isWhite: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>
  .hds-tabs {
    height: 54px;
  }
  .hds-tabs-item {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    white-space: nowrap;
    border: none;
    padding: 14px;
    transition: all .3s ease;
    margin: 0;
  }

  .hds-tabs-item-active {
    color: #0014a0;
  }

  .hds-tabs-item:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: transparent;
    height: 5px;
    width: 100%;
    transition: all .2s ease-in-out;
 }
  .hds-tabs-item-active:after,
  .hds-tabs-item:hover:after {
    background-color: #0014a0;
  }
  .white-menu .hds-tabs-item {
    color: #fff !important;
  }
  .white-menu .hds-tabs-item:hover:after,
  .white-menu .hds-tabs-item-active:after {
    background: #fff;
  }
</style>
