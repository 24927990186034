<template>
  <div id="app" :key="key" :class="{ 'api-page-api': isApiPage }">

    <api-header />

    <div class="hds-layout-container">
      <div class="hds-layout-main">

          <router-view></router-view>

      </div>
    </div>

    <api-footer v-if="!isApiPage" />

  </div>
</template>

<script>
import { bus } from '@/./main';
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    'api-header': Header,
    'api-footer': Footer,
  },
    computed: {
      isApiPage() {
          if ( this.$route.path.startsWith("/api-reference") ) {
              return true;
          }
          return false;
        }
    },
  data() {
    return {
      key: 0
    }
  },
  created() {
    bus.$on('changeLocale', () => {
      this.key += 1;
    })
  }
}
</script>

<style>
  #app {
    position: relative;
    padding-bottom: 100px;
    min-height: 100%;
  }
  #app.api-page-api {
    padding-bottom: 0;
  }
  #app .hds-container {
    max-width: 1070px;
  }
  #app p {
    font-size: 16px;
    line-height: 140%;
  }
  #app a {
    transition: all .2s ease-in-out;
  }
  .api-page-content {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .hds-layout-main {
      max-width: 100%;
  }
</style>
